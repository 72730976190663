<template>
    <div> 
        <!--Header Components-->
        <Header/>
        <!--End Footer Components--> 
        <slot/>
        <!--Header Components-->
        <Footer/>
        <!--End Footer Components-->
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'MainPage',

    components: {
        Header,  Footer 
    },
}
</script>

<style lang="scss" scoped>

</style>