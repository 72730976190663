<template>
	<div>
		<section class="notFound marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-6 order-md-frist order-last">
						<h1>Oops! We lost it...</h1>
						<p>
							Unfortunately, we can’t find what you are looking for. You can
							simply go back to the previous page, go to homepage or contact our
							support team.
						</p>
						<div class="notfoundbtn">
							<router-link :to="`/${$i18n.locale}`" class="callbtn2"
								>Go to homepage</router-link
							>
							<router-link :to="`/${$i18n.locale}/contact`"
								>Contact us</router-link
							>
						</div>
					</div>
					<div class="col-md-6 order-md-last order-frist">
						<svg
							width="629"
							height="246"
							viewBox="0 0 629 246"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M170.647 159.279C179.631 159.279 184.124 163.603 184.124 172.25V179.663C184.124 188.31 179.631 192.634 170.647 192.634H151.78V227.504C151.78 236.264 147.4 240.644 138.64 240.644H120.278C116.011 240.644 112.698 239.521 110.339 237.275C108.093 234.917 106.97 231.66 106.97 227.504V192.634H12.9712C4.32373 192.634 0 188.31 0 179.663V170.229C0 166.298 0.561523 163.098 1.68457 160.627C2.80762 158.044 4.77295 155.068 7.58057 151.699L107.307 28.3882C109.778 25.356 111.687 23.2222 113.035 21.9868C114.382 20.6392 116.404 19.4038 119.099 18.2808C121.907 17.0454 125.22 16.4277 129.038 16.4277H138.64C142.795 16.4277 145.996 17.6631 148.242 20.1338C150.601 22.4922 151.78 25.749 151.78 29.9043V159.279H170.647ZM46.8311 159.279H107.307V83.3052L46.8311 159.279Z"
								fill="#347362"
							/>
							<path
								d="M400.525 128.663C400.525 163.365 391.709 191.441 374.077 212.892C356.445 234.342 333.479 245.067 305.178 245.067C276.877 245.067 253.911 234.342 236.279 212.892C218.76 191.441 210 163.365 210 128.663C210 105.641 213.987 85.3135 221.96 67.6816C229.934 50.0498 241.108 36.4609 255.483 26.915C269.971 17.2568 286.536 12.4277 305.178 12.4277C323.821 12.4277 340.386 17.2568 354.873 26.915C369.36 36.4609 380.591 50.0498 388.564 67.6816C396.538 85.3135 400.525 105.641 400.525 128.663ZM255.82 128.663C255.82 151.686 260.369 170.328 269.465 184.591C278.562 198.854 290.466 205.985 305.178 205.985C319.89 205.985 331.794 198.854 340.891 184.591C349.988 170.328 354.536 151.686 354.536 128.663C354.536 105.528 349.988 86.8296 340.891 72.5669C331.794 58.3042 319.89 51.1729 305.178 51.1729C290.466 51.1729 278.562 58.3042 269.465 72.5669C260.369 86.8296 255.82 105.528 255.82 128.663Z"
								fill="#EF8451"
							/>
							<path
								d="M590.647 159.279C599.631 159.279 604.124 163.603 604.124 172.25V179.663C604.124 188.31 599.631 192.634 590.647 192.634H571.78V227.504C571.78 236.264 567.4 240.644 558.64 240.644H540.278C536.011 240.644 532.698 239.521 530.339 237.275C528.093 234.917 526.97 231.66 526.97 227.504V192.634H432.971C424.324 192.634 420 188.31 420 179.663V170.229C420 166.298 420.562 163.098 421.685 160.627C422.808 158.044 424.773 155.068 427.581 151.699L527.307 28.3882C529.778 25.356 531.687 23.2222 533.035 21.9868C534.382 20.6392 536.404 19.4038 539.099 18.2808C541.907 17.0454 545.22 16.4277 549.038 16.4277H558.64C562.795 16.4277 565.996 17.6631 568.242 20.1338C570.601 22.4922 571.78 25.749 571.78 29.9043V159.279H590.647ZM466.831 159.279H527.307V83.3052L466.831 159.279Z"
								fill="#EDCB50"
							/>
							<path
								d="M255.329 203.096L255.331 203.099C273.157 224.785 296.409 235.639 325.002 235.639C353.596 235.639 376.848 224.785 394.674 203.099C412.487 181.429 421.349 153.11 421.349 118.235C421.349 95.0974 417.342 74.6254 409.3 56.8419C401.258 39.059 389.911 25.3152 375.249 15.6536C360.587 5.87913 343.827 1 325.002 1C306.177 1 289.417 5.87952 274.754 15.6548C260.204 25.3168 248.915 39.0605 240.874 56.8419C232.831 74.6254 228.824 95.0974 228.824 118.235C228.824 153.108 237.63 181.426 255.329 203.096ZM290.133 173.625C281.169 159.571 276.645 141.134 276.645 118.235C276.645 95.2234 281.17 76.7301 290.133 62.6769C299.078 48.6515 310.688 41.7451 325.002 41.7451C339.317 41.7451 350.927 48.6515 359.872 62.6769C368.835 76.7301 373.36 95.2234 373.36 118.235C373.36 141.134 368.836 159.571 359.872 173.625C350.927 187.651 339.317 194.557 325.002 194.557C310.688 194.557 299.078 187.651 290.133 173.625Z"
								fill="#F5F3EE"
								stroke="#EF8451"
								stroke-width="2"
							/>
							<path
								d="M32.9712 183.206H125.97V217.077C125.97 221.415 127.147 224.945 129.615 227.537L129.632 227.554L129.65 227.571C132.246 230.044 135.836 231.216 140.278 231.216H158.64C163.174 231.216 166.758 230.082 169.202 227.639C171.646 225.195 172.78 221.611 172.78 217.077V183.206H190.647C195.284 183.206 198.947 182.091 201.448 179.684C203.956 177.27 205.124 173.723 205.124 169.235V161.823C205.124 157.335 203.956 153.788 201.448 151.374C198.947 148.967 195.284 147.852 190.647 147.852H172.78V19.4766C172.78 15.1345 171.544 11.6039 168.966 9.01583C166.487 6.29952 162.994 5 158.64 5H149.038C145.118 5 141.665 5.63333 138.705 6.93375C135.961 8.07807 133.817 9.36873 132.343 10.8364C130.94 12.1258 128.998 14.3027 126.532 17.3288L126.53 17.3316L26.8123 140.631C26.8108 140.633 26.8092 140.635 26.8076 140.636C23.9741 144.037 21.9465 147.092 20.7708 149.793C19.5707 152.437 19 155.791 19 159.801V169.235C19 173.713 20.1201 177.255 22.5357 179.67C24.9512 182.086 28.4933 183.206 32.9712 183.206ZM126.307 75.7394V147.852H68.9052L126.307 75.7394Z"
								fill="#F5F3EE"
								stroke="#347362"
								stroke-width="2"
							/>
							<path
								d="M454.971 183.206H547.97V217.077C547.97 221.415 549.147 224.945 551.615 227.537L551.632 227.554L551.65 227.571C554.246 230.044 557.836 231.216 562.278 231.216H580.64C585.174 231.216 588.758 230.082 591.202 227.639C593.646 225.195 594.78 221.611 594.78 217.077V183.206H612.647C617.284 183.206 620.947 182.091 623.448 179.684C625.956 177.27 627.124 173.723 627.124 169.235V161.823C627.124 157.335 625.956 153.788 623.448 151.374C620.947 148.967 617.284 147.852 612.647 147.852H594.78V19.4766C594.78 15.1345 593.544 11.6039 590.966 9.01583C588.487 6.29952 584.994 5 580.64 5H571.038C567.118 5 563.665 5.63333 560.705 6.93375C557.961 8.07807 555.817 9.36873 554.343 10.8364C552.94 12.1258 550.998 14.3027 548.532 17.3288L548.53 17.3316L448.812 140.631C448.811 140.633 448.809 140.635 448.808 140.636C445.974 144.037 443.946 147.092 442.771 149.793C441.571 152.437 441 155.791 441 159.801V169.235C441 173.713 442.12 177.255 444.536 179.67C446.951 182.086 450.493 183.206 454.971 183.206ZM548.307 75.7394V147.852H490.905L548.307 75.7394Z"
								fill="#F5F3EE"
								stroke="#EDCB50"
								stroke-width="2"
							/>
						</svg>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import MainPage from "@/layouts/MainPage.vue";
	export default {
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		}, //Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "noindex,nofollow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
