<template>
    <div>
       <footer>
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="logofoot"><img src="@/assets/images/logo-white.svg" alt="SEO Suite"></div>
                    </div>
                </div>
                <div class="row align-items-end">
                    <div class="col-md-3">
                        <p>Access powerful professionally automated SEO tools.</p>
                    </div>
                    <div class="col-md-6">
                        <ul class="footernav">
                            <li><router-link :to="`/${$i18n.locale}/terms-of-service`" >Terms of Service</router-link></li>
                            <li><router-link :to="`/${$i18n.locale}/privacy-policy`" >Privacy Policy</router-link></li>
                            <li><router-link :to="`/${$i18n.locale}/cookie-policy`"  class="norborder">Cookie Policy</router-link></li>
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <p class="scright">© 2021 SEO Suite</p>
                    </div>
                </div>
            </div>
       </footer>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>