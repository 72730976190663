import Vue from "vue";
import Router from "vue-router";
import Home from "./../views/Home.vue";
import NotFound from "./../views/404.vue";

Vue.use(Router);
export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/:lang(en|it)?",
			component: {
				render(c) {
					return c("router-view");
				},
			},

			children: [
				{
					path: "/",
					name: "Home",
					component: Home,
				},
				{
					path: "why-us",
					name: "Why Us",
					component: () => import("./../views/Why_Us.vue"),
				},
				{
					path: "integrations",
					name: "Integrations",
					component: () => import("./../views/Integrations.vue"),
				},
				{
					path: "technical-analysis",
					name: "Technical_Analysis",
					component: () => import("./../views/Technical_Analysis.vue"),
				},

				{
					path: "blog",
					name: "Blog",
					component: () => import("./../views/Blog.vue"),
				},
				{
					path: "blog/:link",
					name: "SinglePost",
					component: () => import("./../views/Blog_Single.vue"),
				},
				{
					path: "brand-reputation",
					name: "Brand Reputation",
					component: () => import("./../views/Brand_Reputation.vue"),
				},
				{
					path: "contact",
					name: "Contact",
					component: () => import("./../views/Contact.vue"),
				},
				{
					path: "content-analysis",
					name: "Content Analysis",
					component: () => import("./../views/Content_Analysis.vue"),
				},
				{
					path: "content-generator",
					name: "Content Generator",
					component: () => import("./../views/Content_Generator.vue"),
				},
				{
					path: "cookie-policy",
					name: "Cookie Policy",
					component: () => import("./../views/Cookie_Policy.vue"),
				},
				{
					path: "how-it-works",
					name: "How It Works",
					component: () => import("./../views/How_It_Works.vue"),
				},
				{
					path: "crm",
					name: "CRM",
					component: () => import("./../views/CRM.vue"),
				},
				{
					path: "prices",
					name: "Prices",
					component: () => import("./../views/Prices.vue"),
				},
				{
					path: "privacy-policy",
					name: "Privacy Policy",
					component: () => import("./../views/Privacy_Policy.vue"),
				},
				{
					path: "project-management",
					name: "Project Management",
					component: () => import("./../views/Project_Management.vue"),
				},
				{
					path: "terms-of-service",
					name: "Terms of Service",
					component: () => import("./../views/Terms_of_Service.vue"),
				},
				{
					path: "/*",
					name: "404 Error",
					component: NotFound,
				},
			],
		},
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});
