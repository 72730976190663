<template>
    <div>
        <section class="callsection">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div class="call_action  border-bottom-sc">
                            <h3>Start automating now!</h3>
                            <p>Try SEO Suite for 7 days, and explore all the tools you need to analyze, monitor and enchance your website.</p>
                            <a :href="`https://my.seosuite.io/${$i18n.locale}/signup`" target="_blank" class="callbtn">Start free trial</a> 
                        </div>
                    </div>
                </div>
            </div>
        </section>  
    </div>
</template>

<script>

    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>