<template>
	<ul class="sclang">
		<button
			href="#"
			class="flex items-center"
			@click="toggleVisibility"
			@keydown.space.exact.prevent="toggleVisibility"
			@keydown.esc.exact="hideDropdown"
			@keydown.shift.tab="hideDropdown"
			@keydown.up.exact.prevent="startArrowKeys"
			@keydown.down.exact.prevent="startArrowKeys"
		>
			<img :src="`/flag_${$i18n.locale}.png`" alt="flag" class="w-8 h-8" />
			<span class="ml-2">{{ $i18n.locale }}</span>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="11.116"
				height="6.29"
				viewBox="0 0 11.116 6.29"
			>
				<path
					id="down-arrow"
					d="M2.194,1.07A.733.733,0,0,0,1.158,2.106L5.983,6.93a.734.734,0,0,0,1.037,0l4.825-4.825A.733.733,0,1,0,10.808,1.07L6.5,5.377Z"
					transform="translate(-0.943 -0.855)"
					fill="#0F393B"
					fill-rule="evenodd"
				/>
			</svg>
		</button>
		<transition name="dropdown-fade">
			<ul
				v-on-clickaway="hideDropdown"
				v-if="isVisible"
				ref="dropdown"
				class="droplangsc"
			>
				<li class="chooselangsc">Choose Language</li>
				<li class="hoverlng">
					<a
						href="#"
						@click.prevent="setLocale('en')"
						ref="account"
						class="flex items-center px-3 py-3 hover:bg-gray-200"
						@keydown.up.exact.prevent=""
						@keydown.tab.exact="focusNext(false)"
						@keydown.down.exact.prevent="focusNext(true)"
						@keydown.esc.exact="hideDropdown"
					>
						<img
							src="@/assets/images/flag_en.png"
							alt="english flag"
							class="h-8 w-8"
						/>
						<span class="ml-2">English</span>
					</a>
				</li>

				<li class="hoverlng lastlang">
					<a
						href="#"
						@click.prevent="setLocale('it')"
						class="flex items-center px-3 py-3 hover:bg-gray-200"
						@keydown.shift.tab="focusPrevious(false)"
						@keydown.up.exact.prevent="focusPrevious(true)"
						@keydown.down.exact.prevent=""
						@keydown.tab.exact="hideDropdown"
						@keydown.esc.exact="hideDropdown"
					>
						<img
							src="@/assets/images/flag_it.png"
							alt="Italian flag"
							class="h-8 w-8"
						/>
						<span class="ml-2">Italian</span>
					</a>
				</li>
			</ul>
		</transition>
	</ul>
</template>

<script>
	import { mixin as clickaway } from "vue-clickaway";
	export default {
		mixins: [clickaway],
		data() {
			return {
				isVisible: false,
				focusedIndex: 0,
			};
		},
		methods: {
			toggleVisibility() {
				this.isVisible = !this.isVisible;
			},
			hideDropdown() {
				this.isVisible = false;
				this.focusedIndex = 0;
			},
			startArrowKeys() {
				if (this.isVisible) {
					// this.$refs.account.focus()
					this.$refs.dropdown.children[0].children[0].focus();
				}
			},
			focusPrevious(isArrowKey) {
				this.focusedIndex = this.focusedIndex - 1;
				if (isArrowKey) {
					this.focusItem();
				}
			},
			focusNext(isArrowKey) {
				this.focusedIndex = this.focusedIndex + 1;
				if (isArrowKey) {
					this.focusItem();
				}
			},
			focusItem() {
				this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
			},
			setLocale(locale) {
				this.$i18n.locale = locale;
				localStorage.setItem("locale", locale);
				this.$router.push({
					params: { lang: locale },
				});
				this.hideDropdown();
			},
		},
	};
</script>

<style scoped>
	.dropdown-fade-enter-active,
	.dropdown-fade-leave-active {
		transition: all 0.1s ease-in-out;
	}
	.dropdown-fade-enter,
	.dropdown-fade-leave-to {
		opacity: 0;
		transform: translateY(-12px);
	}
</style>
