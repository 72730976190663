<template>
	<li class="mainmega" @click="showMenu" @mouseleave="hideMenu">
		<a class="featuredmega" :class="{ active__SC }" @click="active__SC = !active__SC"
			>Features <Icon type="ios-arrow-down"
		/></a>
		<transition name="mega-menu-fade">
			<div  class="sc-mega hide" :class="{ active__SC }">
				<div class="scmegadown">
					<svg
						width="30"
						height="26"
						viewBox="0 0 30 26"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M15 0L0 15V25.5H30V15L15 0Z" fill="#F5F3EE" />
					</svg>
				</div>
				<div class="bgMega">
					<div class="row">
						<div class="col-md-12"><h4>Key features:</h4></div>
					</div>
					<!--Start row-->
					<div class="row">
						<!--Box-->
						<div class="col-md-6">
							<div class="megaBox color1" @click="active__SC = !active__SC">
								<router-link
									:to="`/${$i18n.locale}/technical-analysis`"
									
								>
									<h3>
										<svg
											width="30"
											height="31"
											viewBox="0 0 30 31"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												fill-rule="evenodd"
												clip-rule="evenodd"
												d="M27.622 9.61114L26.7122 8.07327C25.9425 6.77201 24.2367 6.3231 22.8989 7.06975C22.2621 7.43514 21.5022 7.53881 20.7869 7.35789C20.0715 7.17697 19.4594 6.72632 19.0856 6.10533C18.8451 5.71061 18.7158 5.26103 18.7109 4.80205C18.7326 4.06619 18.4476 3.3531 17.9208 2.82524C17.394 2.29738 16.6703 1.9997 15.9145 2H14.0814C13.341 2 12.6311 2.28738 12.1088 2.79858C11.5865 3.30978 11.2948 4.00264 11.2984 4.72385C11.2764 6.2129 10.0308 7.40876 8.50196 7.4086C8.03075 7.40384 7.56919 7.27795 7.16395 7.04369C5.82618 6.29703 4.12037 6.74594 3.35064 8.04721L2.37389 9.61114C1.6051 10.9108 2.05969 12.5713 3.39078 13.3255C4.256 13.8121 4.78901 14.7113 4.78901 15.6844C4.78901 16.6576 4.256 17.5568 3.39078 18.0434C2.06138 18.7925 1.60629 20.4489 2.37389 21.7447L3.29712 23.2956C3.65777 23.9294 4.26288 24.3972 4.97854 24.5953C5.69421 24.7934 6.46143 24.7056 7.11043 24.3512C7.74843 23.9886 8.50871 23.8893 9.22228 24.0752C9.93584 24.2612 10.5436 24.7172 10.9104 25.3417C11.1509 25.7364 11.2801 26.186 11.285 26.645C11.285 28.1493 12.537 29.3688 14.0814 29.3688H15.9145C17.4537 29.3688 18.7036 28.1573 18.7109 26.658C18.7073 25.9345 19.0008 25.2397 19.526 24.7281C20.0512 24.2165 20.7646 23.9307 21.5074 23.9342C21.9774 23.9464 22.4371 24.0718 22.8454 24.2991C24.1796 25.0479 25.8844 24.6051 26.6587 23.3086L27.622 21.7447C27.9949 21.1212 28.0973 20.3787 27.9064 19.6816C27.7156 18.9844 27.2472 18.3901 26.6052 18.0303C25.9631 17.6705 25.4948 17.0762 25.3039 16.3791C25.113 15.6819 25.2154 14.9394 25.5883 14.316C25.8308 13.9036 26.1818 13.5617 26.6052 13.3255C27.9283 12.5717 28.3818 10.9209 27.622 9.62417V9.61114Z"
												stroke="#347362"
												stroke-width="2.16"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
											<path
												d="M15.0049 19.437C17.1331 19.437 18.8584 17.7565 18.8584 15.6836C18.8584 13.6106 17.1331 11.9301 15.0049 11.9301C12.8767 11.9301 11.1515 13.6106 11.1515 15.6836C11.1515 17.7565 12.8767 19.437 15.0049 19.437Z"
												stroke="#347362"
												stroke-width="2.16"
												stroke-linecap="round"
												stroke-linejoin="round"
											/>
										</svg>
										<span>Technical Analysis</span>
									</h3>
									<p>
										Improve performance of your website by identifying and
										examining bugs and errors.
									</p>
								</router-link>
							</div>
						</div>
						<!--END-->
						<!--Box-->
						<div class="col-md-6">
							<div class="megaBox color2" @click="active__SC = !active__SC">
								<router-link
									:to="`/${$i18n.locale}/project-management`" 
								>
									<h3>
										<svg
											width="30"
											height="32"
											viewBox="0 0 30 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M22 12.5L15.2236 15.8882C15.0828 15.9586 14.9172 15.9586 14.7764 15.8882L8 12.5M22 12.5L28.1056 9.44721C28.4741 9.26295 28.4741 8.73705 28.1056 8.55279L15.2236 2.1118C15.0828 2.04142 14.9172 2.04142 14.7764 2.1118L1.89443 8.55279C1.5259 8.73705 1.5259 9.26295 1.89443 9.44721L8 12.5M22 12.5L28.1056 15.5528C28.4741 15.737 28.4741 16.263 28.1056 16.4472L22 19.5M8 12.5L1.89443 15.5528C1.5259 15.737 1.5259 16.263 1.89443 16.4472L8 19.5M22 19.5L15.2236 22.8882C15.0828 22.9586 14.9172 22.9586 14.7764 22.8882L8 19.5M22 19.5L28.1056 22.5528C28.4741 22.737 28.4741 23.263 28.1056 23.4472L15.2236 29.8882C15.0828 29.9586 14.9172 29.9586 14.7764 29.8882L1.89443 23.4472C1.5259 23.263 1.5259 22.737 1.89443 22.5528L8 19.5"
												stroke="#4952A7"
												stroke-width="2.16"
											/>
										</svg>
										<span>Project Management</span>
									</h3>
									<p>
										The easiest way for teams to know who's doing what by when.
										Set up tasks and track your success.
									</p>
								</router-link>
							</div>
						</div>
						<!--END-->
					</div>
					<!--END Row-->
					<!--Start row-->
					<div class="row">
						<!--Box-->
						<div class="col-md-6">
							<div class="megaBox color3" @click="active__SC = !active__SC">
								<router-link
									:to="`/${$i18n.locale}/content-analysis`" 
								>
									<h3>
										<svg
											width="30"
											height="31"
											viewBox="0 0 30 31"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M9.5 6.97368H20.5M9.5 11.2368H20.5M9.5 15.5H15.6875M22.5625 19.0526H19.125M15.6875 19.0526H19.125M19.125 19.0526V25.4474M26 5V26C26 27.6569 24.6569 29 23 29H7C5.34315 29 4 27.6569 4 26V5C4 3.34315 5.34315 2 7 2H23C24.6569 2 26 3.34315 26 5Z"
												stroke="#EDCB50"
												stroke-width="2.16"
												stroke-linejoin="round"
											/>
										</svg>
										<span>Content Analysis</span>
									</h3>
									<p>
										Make your content perfect for both people and search
										engines.
									</p>
								</router-link>
							</div>
						</div>
						<!--END-->
						<!--Box-->
						<div class="col-md-6">
							<div class="megaBox color4" @click="active__SC = !active__SC">
								<router-link
									:to="`/${$i18n.locale}/brand-reputation`" 
								>
									<h3>
										<svg
											width="32"
											height="32"
											viewBox="0 0 32 32"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="5.5"
												cy="23"
												r="3.5"
												stroke="#EB5858"
												stroke-width="2.16"
											/>
											<circle
												r="3.5"
												transform="matrix(1 0 0 -1 26.5 23)"
												stroke="#EB5858"
												stroke-width="2.16"
											/>
											<circle
												r="3.5"
												transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 16 5.5)"
												stroke="#EB5858"
												stroke-width="2.16"
											/>
											<path
												d="M10.75 28.25C10.75 28.25 13.0955 30 16 30C18.9045 30 21.25 28.25 21.25 28.25"
												stroke="#EB5858"
												stroke-width="2.16"
												stroke-linejoin="round"
											/>
											<path
												d="M26.5 16.875C26.5 16.875 26.5 15.125 25.3598 12.8425C24.2196 10.5601 22.125 9 22.125 9"
												stroke="#EB5858"
												stroke-width="2.16"
												stroke-linejoin="round"
											/>
											<path
												d="M5.5 16.875C5.5 16.875 5.5 15.125 6.64022 12.8425C7.78043 10.5601 9.875 9 9.875 9"
												stroke="#EB5858"
												stroke-width="2.16"
												stroke-linejoin="round"
											/>
										</svg>
										<span>Web Reputation</span>
									</h3>
									<p>
										Your brand's success hinge on having a great online
										reputation.
									</p>
								</router-link>
							</div>
						</div>
						<!--END-->
					</div>
					<!--END Row-->
					<!--Start row-->
					<div class="row">
						<!--Box-->
						<div class="col-md-6">
							<div class="megaBox color5" @click="active__SC = !active__SC">
								<router-link
									:to="`/${$i18n.locale}/content-generator`" 
								>
									<h3>
										<svg
											width="30"
											height="31"
											viewBox="0 0 30 31"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M28.4074 5.11109L28.4084 5.1121C29.0834 5.78533 29.0844 6.86686 28.3736 7.54382L28.364 7.5529L28.3547 7.56221L24.1404 11.7761L18.2149 5.80867L22.4331 1.58951L22.4332 1.5894C23.1093 0.912917 24.2089 0.912624 24.8859 1.5896L28.4074 5.11109ZM8.58213 27.3906L1.12003 28.883L2.61367 21.4148L15.3693 8.65921L21.3383 14.6344L8.58213 27.3906ZM8.62423 27.3821C8.62426 27.3821 8.62428 27.3821 8.62431 27.3821L8.62423 27.3821Z"
												stroke="#EF8451"
												stroke-width="2.16"
											/>
											<path
												d="M13.3301 29.166H29.9967"
												stroke="#EF8451"
												stroke-width="2.16"
											/>
										</svg>
										<span>Content Generator</span>
									</h3>
									<p>
										Advanced AI-powered tool to write blogs, social media, ADS,
										sales letters & more.
									</p>
								</router-link>
							</div>
						</div>
						<!--END-->
						<!--Box-->
						<div class="col-md-6">
							<div class="megaBox color6" @click="active__SC = !active__SC">
								<router-link :to="`/${$i18n.locale}/crm`"  >
									<h3>
										<svg
											width="30"
											height="30"
											viewBox="0 0 30 30"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M2.00073 22.1216V7.87843C2.00073 6.28872 3.28945 5 4.87916 5H24.3223C25.912 5 27.2007 6.28872 27.2007 7.87843V22.1216C27.2007 23.7113 25.912 25 24.3223 25H4.87916C3.28945 25 2.00073 23.7113 2.00073 22.1216Z"
												stroke="#A656E4"
												stroke-width="2.15882"
												stroke-linejoin="round"
											/>
											<path
												d="M2.00073 10.7148H27.2007"
												stroke="#A656E4"
												stroke-width="2.15882"
											/>
											<path
												d="M6.20117 15L6.20117 16.7857L6.20117 22.1429"
												stroke="#A656E4"
												stroke-width="2.15882"
											/>
											<path
												d="M24.4006 16.4277L15.6506 16.4277L10.4006 16.4277"
												stroke="#A656E4"
												stroke-width="2.15882"
											/>
											<path
												d="M24.4006 20.7168L15.6506 20.7168L10.4006 20.7168"
												stroke="#A656E4"
												stroke-width="2.15882"
											/>
										</svg>
										<span>CRM</span>
									</h3>
									<p>
										Build emails, track activities and know what lies ahead.
										Totally customizable CRM for every business.
									</p>
								</router-link>
							</div>
						</div>
						<!--END-->
					</div>
					<!--END Row-->
				</div>
			</div>
		</transition>
	</li>
</template>

<script>
	export default {
		mounted() {
			this.menuItems = document.querySelectorAll(".megaBox a");
		},
		data() {
			return {
        active__SC:false,
				isVisible: false,
				menuItems: null,
			};
		},
		methods: {
			showMenu() {
				this.isVisible = true;
			},
			hideMenu() {
				this.isVisible = false;
			},
		},
	};
</script>

<style scoped>
	.mega-menu-fade-enter-active,
	.mega-menu-fade-leave-active {
		transition: all 0.1s ease-in-out;
	}
	.mega-menu-fade-enter,
	.mega-menu-fade-leave-to {
		opacity: 0;
		transform: translateY(-12px);
	}
</style>
