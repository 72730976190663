import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//For meta info
import VueMeta from "vue-meta";
Vue.use(VueMeta);

//For iview UI
import iView from "iview";
import "iview/dist/styles/iview.css";
Vue.use(iView);

//For bootstrap
import { BootstrapVue } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
//Vue.use(IconsPlugin)

//For Scrollspy
import Scrollspy from "vue2-scrollspy";
Vue.use(Scrollspy);

//custom fonts
import "@/assets/fonts/fonts.css";

//stylesheet CSS
import "@/assets/css/style.css";
import "@/assets/css/custom.css";
import "@/assets/css/responsive.css";

//for multilanguage
import i18n from "./i18n";

Vue.config.productionTip = false;

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
	// use the language from the routing param or default language
	let language = to.params.lang;
	if (!language) {
		language = "en";
	}
	// set the current language for i18n.
	i18n.locale = language;
	//if/else
	if (to.path.includes("/en")) {
		next({
			path: to.path.replace("/en", ""),
			//replace: true,
		});
	} else {
		next();
	} //end condition
}); //end

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
