<template>
	<div>
		<!--start Header-->
		<header id="scnav" class="scheader" :class="{ sticky: active }">
			<!--For Mobile device header-->
			<div class="container mshow mobilenav">
				<div class="row">
					<div class="col-md-8 col-sm-8 col-8">
						<div class="logo">
							<router-link to="/it" v-if="$i18n.locale == 'it'">
								<img src="@/assets/images/logo.svg" alt="SEO Suite" />
							</router-link>
							<router-link to="/" v-else>
								<img src="@/assets/images/logo.svg" alt="SEO Suite" />
							</router-link>
						</div>
					</div>
					<!--Toogle -->
					<div class="col-md-4 col-sm-4 col-4">
						<b-button v-b-toggle.sidebar-1 class="scnavtoogle">
							<svg
								width="24"
								height="19"
								viewBox="0 0 24 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<rect width="24" height="3" rx="1" fill="#0F393B" />
								<rect y="8" width="24" height="3" rx="1" fill="#0F393B" />
								<rect y="16" width="24" height="3" rx="1" fill="#0F393B" />
							</svg>
						</b-button>
					</div>
					<!--end-->
				</div>
			</div>
			<!--sidebar nav-->
			<b-sidebar id="sidebar-1" shadow>
				<template #header="{ hide }">
					<div class="row">
						<div class="col-md-8 col-sm-8 col-8">
							<div class="logo">
								<router-link to="/it" v-if="$i18n.locale == 'it'">
									<img src="@/assets/images/logo.svg" alt="SEO Suite" />
								</router-link>
								<router-link to="/" v-else>
									<img src="@/assets/images/logo.svg" alt="SEO Suite" />
								</router-link>
							</div>
						</div>
						<!--Toogle -->
						<div class="col-md-4 col-sm-4 col-4">
							<div @click="hide" class="close-right">
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect
										x="2.12109"
										width="24"
										height="3"
										rx="1"
										transform="rotate(45 2.12109 0)"
										fill="#0F393B"
									/>
									<rect
										x="19.1074"
										y="2.12109"
										width="24"
										height="3"
										rx="1"
										transform="rotate(135 19.1074 2.12109)"
										fill="#0F393B"
									/>
								</svg>
							</div>
						</div>
						<!--end-->
					</div>
				</template>
				<div class="px-3 py-2">
					<div class="scmblang">
						<Language />
					</div>
					<div class="scmega">
						<ul>
							<MobileMegaMenu />
							<li>
								<router-link :to="`/${$i18n.locale}/how-it-works`"
									>How it works</router-link
								>
							</li>
							<li>
								<router-link :to="`/${$i18n.locale}/why-us`"
									>Why us</router-link
								>
							</li>
							<li>
								<router-link :to="`/${$i18n.locale}/prices`"
									>Prices</router-link
								>
							</li>
							<li>
								<router-link :to="`/${$i18n.locale}/integrations`"
									>Integrations</router-link
								>
							</li>
							<li>
								<router-link :to="`/${$i18n.locale}/blog`"
									>SEO news</router-link
								>
							</li>
						</ul>
					</div>
					<div class="login_get">
						<ul>
							<li class="get-started">
								<a
									:href="`https://my.seosuite.io/${$i18n.locale}/signup`"
									target="_blank"
									>Get started</a
								>
							</li>
							<li class="loginscnav">
								<a
									:href="`https://my.seosuite.io/${$i18n.locale}/login`"
									target="_blank"
									>Log in</a
								>
							</li>
						</ul>
					</div>
				</div>
			</b-sidebar>
			<!--end-->

			<!--mobile device end-->

			<div class="container lshow">
				<div class="row align-items-center">
					<div class="col-md-2">
						<div class="logo">
							<router-link to="/it" v-if="$i18n.locale == 'it'">
								<img src="@/assets/images/logo.svg" alt="SEO Suite" />
							</router-link>
							<router-link to="/" v-else>
								<img src="@/assets/images/logo.svg" alt="SEO Suite" />
							</router-link>
						</div>
					</div>
					<div class="col-md-7 text-center">
						<div class="scmega">
							<ul>
								<MegaMenu />
								<li>
									<router-link :to="`/${$i18n.locale}/how-it-works`"
										>How it works</router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/why-us`"
										>Why us</router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/prices`"
										>Prices</router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/integrations`"
										>Integrations</router-link
									>
								</li>
								<li>
									<router-link :to="`/${$i18n.locale}/blog`"
										>SEO news</router-link
									>
								</li>
							</ul>
						</div>
					</div>
					<div class="col-md-3">
						<div class="login_get">
							<ul>
								<li>
									<a
										:href="`https://my.seosuite.io/${$i18n.locale}/login`"
										target="_blank"
										>Log in</a
									>
								</li>
								<li class="get-started">
									<a
										:href="`https://my.seosuite.io/${$i18n.locale}/signup`"
										target="_blank"
										>Get started</a
									>
								</li>
							</ul>
							<Language />
						</div>
					</div>
				</div>
			</div>
		</header>

		<!--end Header -->
	</div>
</template>

<script>
	import Language from "@/components/Language.vue";
	import MegaMenu from "@/components/MegaMenu.vue";
	import MobileMegaMenu from "@/components/MobileMegaMenu.vue";
	export default {
		components: {
			Language,
			MegaMenu,
			MobileMegaMenu,
		},
		data() {
			return {
				active: false,
			};
		},
		methods: {
			toggleNavClass() {
				if (this.active == false) {
					return "nav";
				} else {
					return "sticky-nav";
				}
			},
		},
		mounted() {
			window.document.onscroll = () => {
				let navBar = document.getElementById("scnav");
				if (window.scrollY > navBar.offsetTop) {
					this.active = true;
				} else {
					this.active = false;
				}
			};
		},
	};
</script>

<style lang="scss" scoped></style>
